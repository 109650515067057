import React from 'react';
import Chart from "react-google-charts";
import Slider from '@material-ui/core/Slider';
import next from "./assets/next-2.png"
import check from "./assets/check.png"
import restart from "./assets/restart.png"
import Spinner from 'react-bootstrap/Spinner'
import 'bootstrap/dist/css/bootstrap.min.css';
import {isMobile} from "react-device-detect";

class SvmGame extends React.Component
{
	constructor(props)
  {
    	super(props);
      this.state =
      {
				// Steigung der Funktion
        m: 1,
				// Verschiebung der Funktion
				b: 5,
				// Steigung der eingeloggten Funktion
				s_m: 1,
				// Verschiebung der eingeloggten Funktion
				s_b: 5,
				// Boolean zur Überprüfung, ob der Benutzer eine Funktion ausgewählt hat
				submit: false,
				// Boolean zur Überprüfung, ob die Eingabe vom Benutzer ausgewertet wird oder ein neues Level geladen wird
				loading: false,
				// Aktueller Score
				score: 0,
				// Aktuelles Level
				level: 1,
				// Farbe der Funtion
				color: "red",
				// Index vom ausgewählten Beispiel
        currentExample: 1,
				// x-Koordinate vom Anfang der Graden
				x_1: 0,
				// y-Koordinate vom Anfang der Graden
				y_1: 0,
				// x-Koordinate vom Ende der Graden
				x_2: 0,
				// y-Koordinate vom Ende der Graden
				y_2: 0,
				// Daten die durch den Chart angezeit werden
        classification_Data:
        [
          ['Wörter', 'Klasse 1', 'Trennlinie', 'Klasse 2', 'TK'],
          [5, 2, null, null, null],
          [2, 4.5, null, null, null],
          [1, 5, null, null, null],
          [4, 4, null, null, null],
          [5, 4, null, null, null],
          [5, 3, null, null, null],
          [4.5, 3, null, null, null],
          [1, null, null, 3, null],
          [1, null, null, 2, null],
          [2, null, null, 1, null],
          [2, null, null, 1.5, null],
          [3, null, null, 0.5, null],
          [3, null, null, 1, null],
          [4, null, null, 2, null],
          [4, null, null, null, 1]
        ]
          };

      this.submit = this.submit.bind(this);
      this.next = this.next.bind(this);
      this.restart = this.restart.bind(this);
  	}

		// Berechnung der x-Koordinate zu einer gegeben y-Koordinate
		// Die Steigung wird aus dem state entnommen
    getX(y)
    {
      return (y-this.state.b)/this.state.m;
    }

		// Berechnung der y-Koordinate zu einer gegeben x-Koordinate
		// Die Steigung wird aus dem state entnommen
    getY(x)
    {
      return this.state.m*x+this.state.b;
    }

		// Berechnung des Scores gegeben die korrekte und angegebene Funktion
		// Repräsentiert durch Steigung und Schnittpunkt mit y-Achse
		// Ergibt sich aus einer gewichteten Betrachtung des b und m Scores.
		// Im besten Fall 100, im schlechtesten 0
    getScore(m_true, m, b_true, b)
    {
      var b_score = 0;
      var m_score = 0;

      var b_diff = Math.abs(b_true - b);
      var m_diff = Math.abs(m_true - m);

      // Berechnung des b Scores
      if (b_diff <= .3)
      {
        b_score = 100;
      }
      else if (b_diff > .3 && b_diff <= 1)
      {
        b_score = 80;
      }
      else if (b_diff > 1 && b_diff <= 2)
      {
        b_score = 60;
      }
      else if (b_diff > 2 && b_diff <= 3)
      {
        b_score = 40;
      }
      else if (b_diff > 3 && b_diff <= 4)
      {
        b_score = 10;
      }
      else
      {
        b_score = 0;
      }

      // Berechnung des m Scores
      if (m_diff <= .05)
      {
        m_score = 100;
      }
      else if (m_diff > .05 && m_diff <= .1)
      {
        m_score = 80;
      }
      else if (m_diff > .1 && m_diff <= .8)
      {
        m_score = 70;
      }
      else if (m_diff > .8 && m_diff <= 1)
      {
        m_score = 60;
      }
      else if (m_diff > 1. && m_diff < 4.5)
      {
        m_score = 10;
      }
      else
      {
        m_score = 0;
      }

      return Math.round((m_score + b_score)/2)
    }

		// Anzeigen der korrekten Funktion sowie Aktualisierung des Scores
    submit(x_1, y_1, x_2, y_2)
    {
      let data = JSON.parse(JSON.stringify(require('./assets/output.json')));

			let classification_Data = this.state.classification_Data;
			classification_Data[0]= ['Wörter', 'Klasse 1', 'Korrekte Funktion', 'Klasse 2', 'Deine Funktion'];

			for (var i = 1; i < classification_Data.length; i++) {
				classification_Data[i].push(null)
			}
      this.setState({loading:true});
      this.setState({m: data[''+this.state.level][this.state.currentExample]["function"]["a"],
			b: data[''+this.state.level][this.state.currentExample]["function"]["b"], submit: true,
			loading: false, score: this.state.score +this.getScore(data[''+this.state.level][this.state.currentExample]["function"]["a"],
			this.state.s_m, data[''+this.state.level][this.state.currentExample]["function"]["b"],this.state.s_b), color: 'green',
			x_1: x_1, y_1: y_1, x_2: x_2, y_2: y_2, classification_Data: classification_Data}, this.findRoutes)
    }

		// Das Level wird um 1 inkrementiert und die neuen Datenpunkte werden angezeigt
    next()
    {
      this.setState({loading:true});

      let data = JSON.parse(JSON.stringify(require('./assets/output.json')));
      if (this.state.level < 10)
      {
				let ce = Math.floor(Math.random() * data[''+(this.state.level+1)].length);
				this.setState({classification_Data: data[''+(this.state.level+1)][ce]["points"]}, this.findRoutes)
				this.setState({currentExample: ce, submit: false, loading: false, s_m: this.state.m, s_b: this.state.b, level: this.state.level + 1, color: "red"});



		  }
      else
      {
         this.setState({level: 11, loading: false});
      }
    }

		// Das Spiel wird neugestartet
    restart()
    {
      let data = JSON.parse(JSON.stringify(require('./assets/output.json')));
      let ce = Math.floor(Math.random() * data['1'].length);
      this.setState({classification_Data: data['1'][ce]["points"], currentExample: ce, submit: false, s_m: this.state.m, s_b: this.state.b, level: 1, color: "red", score: 0})

    }

		// Das Spiel wird Initialisiert
    componentDidMount()
    {
      let data = JSON.parse(JSON.stringify(require('./assets/output.json')));
      let ce = Math.floor(Math.random() * data['1'].length);
      this.setState({classification_Data: data['1'][ce]["points"], currentExample: ce});
    }


  	render()
    {

			// Gültige Anfangs- und Endkoordinaten der Funtion werden berechnet
			// Dies ist nötig, da sonst das Koordinatensystem dynamisch vergrößert wird.

			const min = 0;
      const max = 10; // Festgelegte, maximale Größe des Koordinatensystems
      var x_1 = min;
      var y_1 = this.getY(x_1);
      var x_2 = max;
      var y_2 = this.getY(x_2);

      if (this.getY(x_1) < min)
      {
        y_1 = min;
        x_1 = this.getX(y_1)
      }
      else if (this.getY(x_1) > max)
      {
        y_1 = max;
        x_1 = this.getX(y_1)
      }

      else if (this.getX(y_1) < min)
      {
        x_1 = min
        y_1 = this.getY(x_1)
      }
      else if (this.getX(y_1) > max)
      {
        x_1 = max
        y_1 = this.getY(x_1)
      }
      if (this.getY(x_2) <= min)
      {
        y_2 = min;
        x_2 = this.getX(y_2)
      }
      else if (this.getY(x_2) > max)
      {
        y_2 = max;
        x_2 = this.getX(y_2)
      }
      else if (this.getX(y_2) < min)
      {
        x_2 = max
        y_2 = this.getY(x_2)
      }
      else if (this.getX(y_2) > max)
      {
        x_2 = max
        y_2 = this.getY(x_2)
      }

      if(x_1 < min || x_2 < min)
      {
        x_1 = min;
        x_2 = min;
      }

      if(x_1 > max || x_2 > max)
      {
        x_1 = max;
        x_2 = max;
      }


  		return(
  			<div>
          <h3>SVM-Game</h3>
          <p>
            Hier kannst du nun austesten wie gut du darin bist die Datenpunkte zu trennen.
            Deine Aufgabe ist hier die Gerade mit den Slidern so einzustellen, dass die Punkte optimal getrennt werden.
            Optimal heißt hier eine maximal erreichbare Entfernung der Gerade von allen Punkten.
          </p>

          {this.state.level <= 10
           ?
            <div style={{textAlign: "center", display: "inline-grid", width: "100%"}}>
              <div>
                  <div className = "gameChart">
                    <Chart
                      width="100%"
                      height={isMobile? window.screen.height*0.3: 400}
                      chartType="ScatterChart"
                      loader={<p>Loading Chart</p>}
                      data =
                      {this.state.submit?this.state.classification_Data.concat([[x_1,null,y_1, null, null],
                        [x_2, null, y_2, null, null], [this.state.x_1,null, null, null, this.state.y_1],
	                        [this.state.x_2, null, null, null, this.state.y_2]])
												:this.state.classification_Data.concat([[x_1,null,y_1, null],
                        [x_2, null, y_2, null]])}
                      options =
                      {{
                        hAxis: { title: 'Wörter', minValue: min, maxValue: max , viewWindow: {max: max} },
                        vAxis: { title: 'Nomen', minValue: min, maxValue: max},
                        interpolateNulls: true,
                        pointSize: isMobile? window.screen.height*0.004: 7,
                        series:
                        {
                          1: { lineWidth: isMobile? window.screen.width*0.002: 2, pointSize: 0 , color: this.state.color},
                          2: { color: 'orange'},
													3: { lineWidth: isMobile? window.screen.width*0.002: 2, pointSize: 0 , color: "red"}
                        },
                        legend: { position: isMobile? 'bottom' : 'right' }
                      }}
                    />
                  </div>
                  <div className = "gameInfo">
	                  <br/>
	                  <div>
	                    <b> Score: {this.state.score}</b>
	                    <b style={{paddingLeft: 20}}> Level {this.state.level}/10</b>
	                  </div>
	                  <br/>
	                  <br/>
                  <div>
                    <div style={{ float:'left' }}>
                        <b>Deine Funktion:</b>
                        <p style={{marginRight: 20}}><i>f(x)</i> = {this.state.s_m}x + {this.state.s_b}</p>
                    </div>
                    {this.state.submit
                      ? <div style={{ float:'right', paddingLeft: 20, marginRight: 20}}> <b>Korrekte Funktion:</b> <p><i>f(x)</i> = {this.state.m}x + {this.state.b}</p> </div>
                      : <div></div>
                    }
                  </div>
                  {this.state.submit
                    ?
                    <div className="game">
                      {this.state.loading
                        ?<Spinner animation="border" variant="secondary" />
                        :<input className="game" onClick={this.next} type="image" src={next}alt="next"/>
                      }
                    </div>
                    :
                    <div className="game">
                      {this.state.loading
                      ?<Spinner animation="border" variant="secondary" />
                      :<input className="game" onClick={()=>{this.submit(x_1, y_1, x_2, y_2)}} type="image" src={check}alt="check"/>
                      }
                    </div>
                  }
                  <br/>
                  {!this.state.submit & !this.state.loading
                    ?
                      <div className = "my_slider">
                        <p>Steigung:</p>
                        <Slider
                          value={this.state.m}
                          onChange={(e, val) => {this.props.updateSwipe(false);this.setState({m: val, s_m: val})}}
                          onChangeCommitted={ (e) =>{this.props.updateSwipe(true)}}
                          min={-15}
                          step={0.01}
                          max={15}
                        />
                        <p>Verschiebung:</p>
                        <Slider
                          value={this.state.b}
                          onChange={(e, val) => {this.props.updateSwipe(false); this.setState({b: val, s_b: val})}}
                          onChangeCommitted={ (e) => {this.props.updateSwipe(true)}}
                          min={-50}
                          step={0.01}
                          max={50}
                        />
                      </div>
                    :<div></div>
                  }
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
          :
            <div style={{textAlign: "center"}}>
              <div className = "gameEnd">
                <b>Glückwunsch</b>
                <p>Du hast {this.state.score} von 1000 Punkten erreicht! </p>
                <input className="game" onClick={this.restart} type="image" src={restart} alt="restart"/>
              </div>
            </div>
        }
      </div>);
  	}
}

export default SvmGame;
