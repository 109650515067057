import React from 'react';
import './index.css';

const Impressum = () => {
  return (
    // Impressum der Seite
    <div className="impressum">
      <h1>Impressum</h1>
      <h3>Diese Internetseite wird angeboten von:</h3>
      <p>
          Technische Universität Darmstadt<br/>
          Karolinenplatz 5<br/>
          64289 Darmstadt
      </p>
      <a class="link tel" itemprop="telephone" href="tel:+496151160" title="Anrufen: +496151160"><span class="icon far fa-phone-alt"></span>+49 6151 16-0</a>

      <p>
          vertreten durch die Präsidentin der Technischen Universität Darmstadt, Prof. Dr. Tanja Brühl
      </p>
      <p position="relative">
          Die Technische Universität Darmstadt ist eine rechtsfähige Körperschaft des öffentlichen Rechts gemäß § 1 Abs. 1 i.V.m. § 2 Abs. 1 Nr. 1 HHG (Hessisches Hochschulgesetz vom 14. Dezember 2009, GVBl. I S. 666). Seit dem In-Kraft-Treten des TU Darmstadt-Gesetzes (Gesetz zur organisatorischen Fortentwicklung der Technischen Universität Darmstadt vom 05. Dezember 2004, GVBl. I S. 382, in der Fassung vom 14. Dezember 2009, GVBl. I S. 699) ist sie autonome Universität des Landes Hessen.
      </p>
      <p>
          <a href="https://www.tu-darmstadt.de/impressum/" class="link">
          Impressum der Technischen Universität Darmstadt</a>
      </p>
      <h3>
          Verantwortlich für die Website und die Social-Media-Konten des Fachbereichs Informatik:
      </h3>
      <p>
          Technische Universität Darmstadt<br/>
          Fachbereich Informatik<br/>
          Hochschulstraße 10<br/>
          64289 Darmstadt<br/>
          <a class="link tel" itemprop="telephone" href="tel:+49 6151 16-20870">
              +49 6151 16-20870<br/>
          </a>
          <a class="link email" itemprop="email" href="mailto:karsten.weihe@tu-darmstadt.de" title="E-Mail an: karsten.weihe@tu-darmstadt.de">
          karsten.weihe@tu-darmstadt.de</a>
      </p>
      <p>vertreten durch: <strong>Prof. Dr. Karsten Weihe</strong></p>
    </div>
    );
}

export default Impressum;
