import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { isMobile } from "react-device-detect";
import face_detection from "./assets/face_detection.png"

class SvmApplicationField extends React.Component
{
    shouldComponentUpdate()
    {
      return false;
    }

  	render()
    {
  		return(
        // Vorstellung von beispielhaften Anwendungsgebieten von SVMs (Inhalt 4. Seite)
  	    <div>
          <h3>SVM-Anwendungsgebiete</h3>
          <p>Es gibt zahlreiche Anwendungsgebiete bei denen Support Vector Machines verwendet werden können.
          Ein sehr populärer Anwendungsbereich ist die Handschrifterkennung.
          Die SVM ist nach dem Training in der Lage handschriftlich geschriebene Buchstaben und Zahlen zu erkennen.
          Dies habt ihr auch vielleicht schon in der Schule auf dem Whiteboard gesehen.
          Ein weiterer Bereich in dem SVMs gut eingesetzt werden können, ist bei der Bilderkennung.
          Dort ist der Klassifizierter in der Lage Objekte auf einem Bild zu erkennen und zuzuordnen.
          </p>
          <br/>
          <h3> Stärken und Schwächen von Support Vector Machines </h3>
          <p>Obwohl Support Vector Machines recht simpel sind, bieten sie einige Vorteile.
          Jedoch hat die Methode auch seine Grenzen, weshalb auch andere Techniken entwickelt wurden, um bessere Ergebnisse zu erhalten.
          Einige Stärken und Schwächen sind:
          </p>
          <br/>
          <table className = "PC-table">
              <colgroup>
                  <col style={{ width: "50%" }}/>
                  <col style={{ width: "50%" }}/>
              </colgroup>
              <thead>
                  <tr>
                      <th>Stärken</th>
                      <th>Schwächen</th>
                  </tr>
              </thead>
              <tbody>
                <tr>
                    <td>Sehr effiziente Methode zur Klassifizierung von Objekten</td>
                    <td>F&uuml;r neu hinzukommende Eingabedaten ist jedes Mal ein neues Training erforderlich</td>
                </tr>
                <tr>
                    <td>Relativ geringer Aufwand zur Implementierung</td>
                    <td>Die Berechnung von SVMs ist sehr aufwändig</td>
                </tr>
                <tr>
                    <td>Geometrisch sehr anschauliche Arbeitsweise des Algorithmus</td>
                    <td>Richtige Vorverarbeitung der Daten (z.B. Skalierung, welche Merkmale sind wichtig) ist essentiell</td>
                </tr>
                <tr>
                    <td>Große Generalisierungsf&auml;higkeit zur Lösung realer Probleme</td>
                    <td>Deutlich schlechtere Performance je verrauschter die Trainingsdaten sind </td>
                </tr>
              </tbody>
          </table>

          <div className = "expert">
          <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Expertenwissen: </Accordion.Header>
                <Accordion.Body>
                  SVMs werden auch für kompliziertere Anwendungen verwendet. Eine Anwendungsgebiet ist die Gesichtserkennung. Hier geht es um die Erkennung von Gesichtern auf Bildern und nicht die Identifizierung einer Person. Die SVM betrachtet dabei einen Bereich des Bildes (z.B 25x25 Pixel groß) und klassifiziert diesen in eine der beiden Klassen „Gesicht“ oder „Nicht-Gesicht“. Die Schwierigkeit hierbei ist, dass es sehr große Unterschiede zwischen dem zu findenden Muster gibt wie z. B. die Gesichtsform oder der Aufnahmewinkel. Wie unten im Bild zusehen, erreichen SVMs jedoch sehr gute Ergebnisse.

                  <img src={face_detection} alt="face_detection" width={isMobile?"100%":"40%"} height={isMobile?"100%":"40%"}/>
                  <i>(Quelle: Jun Qin and Zhong-Shi He, "A SVM face recognition method based on Gabor-featured key points," 2005 International Conference on Machine Learning and Cybernetics, 2005, pp. 5144-5149 Vol. 8, doi: 10.1109/ICMLC.2005.1527850.)</i><br />

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
            );
  	}

}

export default SvmApplicationField;
