import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import SvmExample from "./svmExample"
import SvmTheory from "./svmTheory"
import SvmApplicationField from "./svmApplicationField"
import SvmGame from "./svmGame"
import previous from "./assets/previous.png"
import next from "./assets/next.png"
import tuLogo from "./assets/TU_Darmstadt_Logo.png"
import Impressum from "./impressum"

import { isMobile } from "react-device-detect";

class Home extends React.PureComponent
{
	constructor(props)
  {
    super(props);
    this.state = {
										// Status zum Aktivieren und Deaktivieren der Swipe-Funktion
										// Auf mobilen Endgeräten erfolgt die Navigation zwischen der unterschiedlichen Seiten per Swipe-Geste
										// Auf anderen Geräten können Button an den Rändern des Bildschirms genutzt werden um zwischen den Seiten zu navigieren
                    swipe: true,
										// State zum Ein- und Ausblenden des Impressums
                    impressum: false
                 }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.updateSwipe = this.updateSwipe.bind(this);
    this.switch_impressum = this.switch_impressum.bind(this);

  }

	// Funktion für die Aktivierung und Deaktivierung der Swipe-Funktion
  updateSwipe(update) {
	   if (this.state.swipe !== update){
	    this.setState({
	      swipe: update
	    })}
  }

	// Funktion zum Aufrufen der nächste Seite
  next()
  {
    this.slider.slickNext();
  }

	// Funktion zum Aufrufen der vorherigen Seite
  previous()
  {
    this.slider.slickPrev();
  }

	// Funktion zum Aufrufen des Impressums
  switch_impressum()
  {
      const impressum_status = this.state.impressum;
      if (impressum_status)
        {
            this.setState({impressum: false});
        }
      else
        {
            this.setState({impressum: true});
        }
  }

  render()
  {
		// Slidereinstellungen
    const settings =
    {
      dots: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: false,
      swipe: isMobile&&this.state.swipe,
      className: 'slides'
    };

    return(
    <div>
				{/* Button zum Aufrufen der vorherigen Seite */}
        { isMobile || this.state.impressum ? <div> </div>:
            <div className = "left" onClick={this.previous} >
                <input className="button previous" onClick={this.previous} type="image" src={previous} alt="previous"/>
            </div>}

        <div className = "content">
						{/* Überschrift und TU-Darmstadt Logo */}
						<div style = {{overflow: "hidden"}}>
                <h2 style ={{padding: "5% 0",float: "left"}}>Grundlagen der IT</h2>
                <img src={tuLogo} alt="TU Darmstadt" width={isMobile?"30%":"20%"} height={isMobile?"20%":"20%"} style={{float: "right", marginRight: 20}}/>
            </div>

            {this.state.impressum ?
            	<Impressum/>
            :
						// Initialisierung des Sliders
            <div style={{clear: "right"}}>
                <Slider ref={c => (this.slider = c)} {...settings}>
                    <SvmExample/>
                    <SvmGame updateSwipe = {this.updateSwipe.bind(this)}/>
                    <SvmTheory/>
                    <SvmApplicationField/>
                </Slider>
            </div>
            }

						{/* Button zum Ein- und Ausblenden des Impressums */}
            {this.state.impressum ?
							<input className="showimpressum" onClick={this.switch_impressum} type="submit" value="Zurück"/>
            :
							<input className="showimpressum" onClick={this.switch_impressum} type="submit" value="Impressum"/>
            }
        </div>

				{/* Button zum Aufrufen der nächsten Seite */}
        { isMobile || this.state.impressum ? <div> </div>:
						<div className = "right" onClick={this.next}>
            	<input className="button next" onClick={this.next} type="image" src={next} alt="next"/>
            </div>
        }
    </div>
);
  }
}

ReactDOM.render(<Home />, document.getElementById('root'));
